import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Chart from '@components/chart'
import MultiToggleLayout from '@components/layouts/multi-toggle/multi-toggle-layout'
import { ToggleProps } from '@components/selectors/multi-option-buttons'
import useToggleState from '@components/selectors/toggle-index-tracker'
import { REMOUNT_MS } from '@constants/config'
import AuthContext from '@contexts/auth'
import { useDisbursementCurrencies } from '@helpers/currency-hook'
import { getStaleMins } from '@helpers/stale-timer'
import { RisksFilters } from '@interfaces/analytics-risk'
import CohortService from '@services/api-analytics/risk-cohort'
import FilterService from '@services/api-analytics/risk-filter'

import DataTable from './table'

const CollectionCurveL4 = ({ exportable }: { exportable: boolean }) => {
  const { currTabs, mapToggleChange } = useToggleState([0])
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const showHistoricalRateIndicator = useDisbursementCurrencies()
  const [type, setType] = useState('scheduled')

  const principalToggle: ToggleProps = {
    toggleTitle: 'Collection Type',
    toggleSelections: [
      {
        label: 'All Payments',
        textInfo: {
          primary:
            'All payments collected for loans disbursed in a given month (principal, interest, fees, others) divided by the original principal balance.',
        },
        action: () => {
          setType('scheduled')
        },
      },
      {
        label: 'Principal Only',
        textInfo: {
          primary: `All principal payments collected for loans disbursed in a given month divided by the original principal balance.`,
        },
        action: () => {
          setType('principal')
        },
      },
    ],
  }

  const toggles: ToggleProps[] = mapToggleChange([principalToggle])

  const {
    dateStart,
    dateEnd,
    categoryTypes,
    currency = 'USD',
    activeType,
  } = appliedFilters
  const { rates = {}, display_rates = [] } = optionFilters
  const isAggregate = activeType === 'All'

  const avgFilters = {
    date_from: moment(dateStart).format('YYYY-MM-DD'),
    date_to: moment(dateEnd).format('YYYY-MM-DD'),
    filters: isAggregate ? [] : categoryTypes,
    slug_name: company?.slug_name ?? '',
  }

  const {
    data: avgTerm,
    isFetching: avgTermIsFetching,
    isFetched: avgTermIsFetched,
  } = useQuery(
    ['avgTerm', avgFilters],
    () => FilterService.getAvgTerm(avgFilters),
    { ...getStaleMins(), enabled: categoryTypes?.length > 0 }
  )

  const filters: RisksFilters = {
    slug_name: company?.slug_name ?? '',
    currency: currency,
    date_from: moment.utc(dateStart).format('YYYY-MM-DD'),
    date_to: moment.utc(dateEnd).format('YYYY-MM-DD'),
    filters: categoryTypes,
    is_principal: type == 'principal',
  }

  const { error, data, isFetching } = useQuery(
    ['riskCohortCollectionCurve', filters],
    () => CohortService.getCollectionCurve(filters),
    { ...getStaleMins(), enabled: avgTermIsFetched }
  )

  const cohort = (data?.data ?? []).reduce(
    (prev: { cohort: string; color: string; cutoff_age: number }[], cur) => {
      const color = '#' + Math.floor(Math.random() * 16777215).toString(16)
      return prev.findIndex(
        (x: { cohort: string }) => x.cohort === cur.cohort
      ) < 0
        ? [...prev, { cohort: cur.cohort, color, cutoff_age: cur.cutoff_age }]
        : prev
    },
    []
  )

  const mob =
    (data?.data ?? []).length > 0
      ? Math.max(...(data?.data ?? []).map(x => x.cutoff_age))
      : 0

  let maxVal = 0
  const chartData = Array(mob)
    .fill('')
    .map((x, i) => {
      const currentMob = (data?.data ?? []).filter(x => x.mob === i + 1)
      const result: { x: number; [key: string]: number | undefined } = {
        x: i + 1,
      }
      cohort.forEach((y: { cohort: string; cutoff_age: number }) => {
        const currentVal = currentMob.find(x => x.cohort === y.cohort)
        const val =
          currentVal?.numer && currentVal?.denom
            ? (parseFloat(currentVal?.numer.toString()) /
                parseFloat(currentVal?.denom.toString())) *
              100
            : 0

        const valForMaxVal = val / 100
        if (valForMaxVal > maxVal && i + 1 < currentVal?.cutoff_age) {
          maxVal = valForMaxVal
        }

        result[moment(y.cohort).format('MMM-YY')] =
          i + 1 <= y.cutoff_age ? parseFloat(val.toString()) : undefined
      })
      return result
    })

  /**
   * zoom
   */
  const xStart = 0
  const xSpan = Math.ceil(Number(avgTerm ?? 0) / 30) + 5
  const xEnd =
    chartData?.length === 0
      ? 0
      : xSpan >= chartData?.length
      ? chartData?.[chartData?.length - 1]?.x
      : chartData?.[xSpan - (xSpan > 0 ? 1 : 0)].x /
        chartData?.[chartData?.length - 1]?.x

  const export_columns = [
    {
      title: 'Cohort',
      field: 'x',
    },
    ...cohort
      .sort((a, b) => {
        return moment(a.cohort).diff(moment(b.cohort))
      })
      .map((c: { cohort: string; color: string }) => {
        return {
          title: `${moment(c.cohort).format('MMM YY')}`,
          field: `${moment(c.cohort).format('MMM-YY')}`,
        }
      }),
  ]

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [data])

  return (
    <MultiToggleLayout
      toggles={toggles}
      toggleSelection={currTabs}
      exchangeRateIndicator={showHistoricalRateIndicator}
      chart={
        <Chart
          loading={isFetching || isProcessing || avgTermIsFetching}
          id={`riskCohortCollectionCurve__${type}`}
          yLabel={'Collection'}
          yFormat="#.00a%"
          xLabel="Month on Books (MoB)"
          xAxisType="CategoryAxis"
          data={chartData}
          series={cohort.map((c: { cohort: string; color: string }) => {
            const label = `${moment(c.cohort).format('MMM YY')}`
            return {
              label,
              tooltipValueFormat: '#.00a%',
              type: 'SmoothedXLineSeries',
              field: moment(c.cohort).format('MMM-YY'),
              color: c.color,
              hasBullet: false,
            }
          })}
          legendSetting={{
            show: true,
            position: 'right',
            config: { width: 124, paddingLeft: 24 },
          }}
          exportable={exportable}
          exportableColumn={export_columns}
          xlsxOptions={{ pivot: true, addColumnNames: true }}
          scroll={{ y: true, x: true, xStart, xEnd }}
          error={error as { message: string }}
        />
      }
      toggleData={
        <DataTable
          showHistoricalRateIndicator={showHistoricalRateIndicator}
          displayRates={display_rates}
          mob={mob}
          cohort={cohort}
          data={data}
          rates={rates}
          maxVal={maxVal}
        />
      }
    />
  )
}

export default CollectionCurveL4
