import React, { useContext, useEffect, useState } from 'react'

import Chart from '@components/chart'
import MultiToggleLayout from '@components/layouts/multi-toggle/multi-toggle-layout'
import { ToggleProps } from '@components/selectors/multi-option-buttons'
import useToggleState from '@components/selectors/toggle-index-tracker'
import Table from '@components/table'
import { REMOUNT_MS } from '@constants/config'
import AuthContext from '@contexts/auth'
import { useDisbursementCurrencies } from '@helpers/currency-hook'
import { numberFormatter } from '@helpers/number-formatter'
import { useGetRiskData } from '@helpers/risk-charts-data-hook'
import { CollectionService } from '@services/api-analytics/risk-collection'

const FutureCollections = ({ exportable }: { exportable: boolean }) => {
  const { currTabs, mapToggleChange } = useToggleState([1])
  const showHistoricalRateIndicator = useDisbursementCurrencies()
  const { appliedFilters } = useContext(AuthContext)
  const { activeType } = appliedFilters
  const [collectionValue, setCollectionValue] = useState('principal')

  const principalToggle: ToggleProps = {
    toggleTitle: 'Collection Type',
    toggleSelections: [
      {
        label: 'All Payments',
        textInfo: {
          primary:
            'All payments collected for loans disbursed in a given month (principal, interest, fees, others) divided by the original principal balance.',
        },
        action: () => {
          setCollectionValue('total')
        },
      },
      {
        label: 'Principal Only',
        textInfo: {
          primary: `All principal payments collected for loans disbursed in a given month divided by the original principal balance.`,
        },
        action: () => {
          setCollectionValue('principal')
        },
      },
    ],
  }
  const toggles: ToggleProps[] = mapToggleChange([principalToggle])

  const { data, error, isFetching } = useGetRiskData(
    CollectionService.getOutstandingPrincipalByPayoffMonth,
    'outstanding_principal_by_anticipated_payoff_month',
    true,
    false,
    { collection_value: collectionValue }
  )
  let maxVal = 5
  let minVal = 0

  const updateMinMaxVal = (value: number) => {
    const paddingFactor = 1.5
    if (value > maxVal) {
      maxVal = value + value * paddingFactor
    }
    if (value < minVal) {
      minVal = value - value * paddingFactor
    }
  }

  const chartData = data?.reduce((acc: any, curr: any) => {
    updateMinMaxVal(curr['val'])

    const xValue = curr['display_date']
    const existingIndex = acc.findIndex((item: any) => item.x === xValue)

    if (existingIndex >= 0) {
      acc[existingIndex][curr['dpd_bucket']] = curr['val']
    } else {
      const newItem: any = { x: xValue }
      newItem[curr['dpd_bucket']] = curr['val']
      acc.push(newItem)
    }
    return acc
  }, [])

  const series = [
    { label: '0 DPD', field: 'dpd_0' },
    { label: '1-30 DPD', field: 'dpd_1_30' },
    { label: '31-60 DPD', field: 'dpd_31_60' },
    { label: '61-90 DPD', field: 'dpd_61_90' },
    { label: '91-120 DPD', field: 'dpd_91_120' },
    { label: '121-150 DPD', field: 'dpd_121_150' },
    { label: '151-180 DPD', field: 'dpd_151_180' },
    { label: '180+ DPD', field: 'dpd_180' },
  ].map(x => ({
    ...x,
    tooltipValueFormat: '#',
    type: 'ColumnSeries',
    isStack: true,
    field: `${x.field}`,
  })) as any

  const table_columns = [
    {
      title: 'Date',
      field: 'x',
      align: 'center',
      className: 'sticky left-0 min-w-[150px]',
      render: (r: any) => {
        return r.x
      },
    },
    ...series.reduce((p: any[], s: any) => {
      return [
        ...p,
        {
          className: 'min-w-[150px]',
          align: 'right',
          field: s.field,
          title: `${s.label}`,
          render: (r: any) => numberFormatter(r[s.field]),
        } as any,
      ]
    }, []),
  ]

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [data])

  return (
    <MultiToggleLayout
      toggles={toggles}
      toggleSelection={currTabs}
      staticTipInfo={
        <div>
          The sum of the outstanding principal with scheduled principal payments
          in future months, based on the DPD bucket of the loan.
        </div>
      }
      exchangeRateIndicator={showHistoricalRateIndicator}
      isHistorical={false}
      chart={
        <Chart
          loading={isFetching || isProcessing}
          id={`riskCollectionCOutstandingPrincipalByAnticipatedPayoffMonth_${activeType}`}
          yLabel="Outstanding Principal"
          ySetting={{ max: maxVal, min: minVal }}
          yFormat="#a"
          data={chartData}
          series={series}
          xAxisType="CategoryAxis"
          exportable={exportable}
          exportableColumn={table_columns}
          error={error as { message: string }}
        />
      }
      toggleData={
        <Table
          containerClass="[&>table>thead>tr>td]:text-center [&>table>thead>tr>td]:font-semibold"
          loading={isFetching || isProcessing}
          data={chartData}
          columns={table_columns}
        />
      }
    />
  )
}

export default FutureCollections
